import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { ChevronRightIcon } from '@heroicons/react/outline'

export default function Sidebar() {
  return (

            <div className="bg-white ">


                    <div className="flex flex-col flex-grow bg-red-500 rounded-lg text-center py-4 px-4 sm:py-8 sm:px-6 lg:px-8">
                        <h3 className="text-xl font-bold text-white sm:text-2xl">
                        <span className="block">Médecine douce</span>
                        </h3>
                        <p className="mt-4 text-lg leading-6 text-red-200">
                        Médecine alternative, thérapie, soin et bien être. Trouvez un praticien qui vous correspond et proche de chez vous
                        </p>
                        <p className="mt-4 text-lg leading-6 text-red-200">
                          <a href="/sante/medecine-douce/contact/ ">
                            <StaticImage
                              className="bg-white rounded-md"
                              src="../../images/sidebar/Sante.jpg" 
                              alt="Etude de sol"
                            />
                          </a>
                        </p>
                        <p className="mt-4 text-right text-lg leading-6 text-red-200">
                          <a href="/sante/medecine-douce/contact/ " className="underline">
                            En savoir plus
                          </a>
                        </p>
                    </div>

                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Media Kit Koudepouce
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                          Vous êtes un praticien en médecine douce, soin ou bien être ?
                          </p>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                          Bandeau, encart promotionnel, publi information... <br />
                          <a 
                            href="/contact/"
                            className="text-red-500 underline"
                          >
                            Renseignez-vous pour apparaître sur nos supports digitaux
                          </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    
                    <div className="py-4 bg-white">
                      <div className="max-w-7xl">
                        <div className="lg:text-center">
                          <h3 className="mt-2 text-1xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-2xl">
                          Articles et liens
                          </h3>
                          <p className="mt-4 text-lg leading-6 text-gray-900 lg:text-left">
                            <a 
                              href="/sante/bien-etre/acupuncture/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Acupuncture
                            </a>
                            <a 
                              href="/sante/bien-etre/aromatherapie/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Aromathérapie
                            </a>
                            <a 
                              href="/sante/bien-etre/cbd/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />CBD
                            </a>
                            <a 
                              href="/sante/bien-etre/coaching/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Coaching
                            </a>
                            <a 
                              href="/sante/bien-etre/coiffure/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Coiffeurs
                            </a>
                            <a 
                              href="/sante/bien-etre/hamman/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Hamman
                            </a>
                            <a 
                              href="/sante/bien-etre/luminotherapie/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Luminothérapie
                            </a>
                            <a 
                              href="/sante/bien-etre/osteopathie/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Ostéopathie
                            </a>
                            <a 
                              href="/sante/bien-etre/yoga/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Yoga
                            </a>
                            <a 
                              href="/sante/medecine-douce/art-therapeute/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Art thérapeute
                            </a>
                            <a 
                              href="/sante/medecine-douce/dieteticien-nutritionniste/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Diététicien Nutritionniste
                            </a>
                            <a 
                              href="/sante/medecine-douce/hypnotherapeute/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Hypnothérapeute
                            </a>
                            <a 
                              href="/sante/medecine-douce/reflexologue/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Réflexologue
                            </a>
                            <a 
                              href="/sante/medecine-douce/sophrologue/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Sophrologue
                            </a>
                            <a 
                              href="/sante/medecine-douce/naturopathe/"
                              className="text-red-500 underline block"
                            >
                              <ChevronRightIcon className="w-4 h-4 inline mb-1 mx-2" />Naturopathe
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>

                    
            </div>



        )
    }